import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { onModalOpen, onChangeDarkMode, onChangeHighcontrast, OnchangeRTLmode } from '../Redux/Actions/Actions';

import Loader from './SiteLoader/Loader'
import NewModal from '../Component/Comman/NewModal';
// import TestSwap from './TestSwap/TestSwap';


const Header = React.lazy(() => import("../Component/Comman/Header"))
const Deposit = React.lazy(() => import("./Deposit/Deposit"))
const Withdraw = React.lazy(() => import("./Withdraw/Withdraw"))
const Dashboard = React.lazy(() => import("./Dashboard/Dashboard"))
const Swap = React.lazy(() => import("./Swap/Swap"))
const Wallet = React.lazy(() => import("./Wallet/Wallet"))
const History = React.lazy(() => import("./History/Histroy"))
const Staking = React.lazy(() => import("./Staking/Staking"))
const Trade = React.lazy(() => import("./Exchange/Trade"))
const TestSwap = React.lazy(() => import("./TestSwap/TestSwap"))
function MainIndex(props) {
    const { modalopen, darkMode, highcontrast, rtlmode } = props.Mainreducer;
    const { activekey, GotoChangeMenu } = props;
    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;
    return (
        <Suspense fallback={<Loader />}>
        <div className='main'>
            {activekey === "/chat" || activekey === "/documentation" || activekey === "/trade" ? "" :
                <Header
                    onModalOpen={(val) => { props.onModalOpen(true) }}
                    GotoChangeMenu={(val) => { GotoChangeMenu(val) }}
                />
            }
            <NewModal
                show={modalopen}
                highcontrast={highcontrast}
                darkMode={darkMode}
                onHide={(val) => { props.onModalOpen(false) }}
                onChangeDarkMode={() => { props.onChangeDarkMode(darkMode === 'dark' ? 'light' : 'dark') }}
                onChangeHighcontrast={() => { props.onChangeHighcontrast(highcontrast === 'high-contrast' ? 'light' : 'high-contrast') }}
                OnchangeRTLmode={() => { props.OnchangeRTLmode(rtlmode === 'rtl_mode' ? true : false) }}
            />
            <div className="body d-flex px-lg-5 mt-5 ">
                    <Routes>
                        <Route exact path={baseUrl + '/dashboard'} element={<Dashboard />} />
                        <Route exact path={baseUrl + '/deposit'} element={<Deposit />} />
                        <Route exact path={baseUrl + '/withdraw'} element={<Withdraw />} />
                        <Route exact path={baseUrl + '/wallet'} element={<Wallet />} />
                        <Route exact path={baseUrl + '/swap'} element={<Swap />} />
                        <Route exact path={baseUrl + '/staking'} element={<Staking/>} />
                        <Route exact path={baseUrl + '/history'} element={<History />} />
                        <Route exact path={baseUrl + '/trade'} element={<Navigate to={'/trade/BTC_USDT'} />} />
                        <Route exact path={baseUrl + '/trade/:pair'} element={<Trade />} />
                        <Route exact path={baseUrl + '/testSwap'} element={<TestSwap />} />


                    </Routes>
            </div>
        </div>
                </Suspense>
    )
}
const mapStateToProps = ({ Mainreducer }) => ({
    Mainreducer
})
export default connect(mapStateToProps, {
    onModalOpen,
    onChangeDarkMode,
    onChangeHighcontrast,
    OnchangeRTLmode
})(MainIndex);