import React from 'react'

const Contactus = () => {
    return (
        <div>
            <div className='contactus-section py-5'>
                <div className='about-inner'>
                    <div className='container'>

                        <div className='row align-items-center'>
                            <div className='col-lg-8'>
                                <h2>Contact Us</h2>
                            </div>
                            <div className='col-lg-4'>
                                <img src={process.env.PUBLIC_URL + "/images/home/about-img.png"} className='img-fluid mt-3' />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='contact-details mt-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <div className='card'>
                                <div className=' d-lg-flex details gap-2'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="address" width="30" height="30"><path fill="#f9b707" d="M17.378,30.847,31.205,52.581a.942.942,0,0,0,1.59,0L46.621,30.847a.86.86,0,0,0,.047-.084,18.058,18.058,0,0,0,3.561-10.792,18.229,18.229,0,1,0-36.458,0,18.053,18.053,0,0,0,3.558,10.787A.864.864,0,0,0,17.378,30.847ZM32,3.625A16.364,16.364,0,0,1,48.345,19.971a16.188,16.188,0,0,1-3.29,9.8.893.893,0,0,0-.117.208L32,50.321,19.067,29.992a.934.934,0,0,0-.122-.217,16.188,16.188,0,0,1-3.29-9.8A16.364,16.364,0,0,1,32,3.625Z"></path><path fill="#f9b707" d="M40.756 50.939a.942.942 0 0 0-.154 1.878c5.906.485 9.432 1.806 9.432 3.532 0 1.279-6.366 3.767-18.034 3.767s-18.034-2.488-18.034-3.767c0-.822.9-2.812 9.281-3.52a.942.942 0 0 0-.159-1.877c-7.406.626-11.006 2.392-11.006 5.4C12.082 60.232 22.407 62 32 62s19.918-1.768 19.918-5.651C51.918 53.326 48.267 51.556 40.756 50.939zM21.9 23.116h2.5v7.935a.941.941 0 0 0 .942.941H38.657a.941.941 0 0 0 .942-.941V23.116h2.5a2.193 2.193 0 0 0 2.109-1.38 1.831 1.831 0 0 0-.622-2.07l-10.1-8.191a2.436 2.436 0 0 0-2.974 0l-10.1 8.19a1.83 1.83 0 0 0-.622 2.069A2.194 2.194 0 0 0 21.9 23.116zm11.734 6.993h-2.9a.917.917 0 0 0 .185-.531V26.265a1.268 1.268 0 1 1 2.535 0v3.313A.917.917 0 0 0 33.632 30.109zM31.7 12.938a.546.546 0 0 1 .6 0l10.1 8.19.033-.023c-.01.025-.1.126-.334.126H38.657a.942.942 0 0 0-.942.942v7.935H35.146a.923.923 0 0 0 .184-.531V26.265a3.151 3.151 0 1 0-6.3 0v3.313a.917.917 0 0 0 .185.531H26.285V22.174a.941.941 0 0 0-.941-.942H21.9c-.233 0-.325-.1-.3-.1z"></path></svg>
                                    </div>
                                    <div>
                                        <h5>Address</h5>
                                        <p>Biovus Blockchain Technologies,salem</p>
                                    </div>
                                </div>
                                </div>
                             
                            </div>
                            <div className='col-lg-4'>
                                <div className='card'>

                                <div className='d-lg-flex details gap-2'>
                                    <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" id="email" width="30" height="30"><path fill="#f9b707" d="M53.42 53.32H10.58a8.51 8.51 0 0 1-8.5-8.5V19.18a8.51 8.51 0 0 1 8.5-8.5h42.84a8.51 8.51 0 0 1 8.5 8.5v25.64a8.51 8.51 0 0 1-8.5 8.5ZM10.58 13.68a5.5 5.5 0 0 0-5.5 5.5v25.64a5.5 5.5 0 0 0 5.5 5.5h42.84a5.5 5.5 0 0 0 5.5-5.5V19.18a5.5 5.5 0 0 0-5.5-5.5Z"></path><path fill="#f9b707" d="M32 38.08a8.51 8.51 0 0 1-5.13-1.71L3.52 18.71a1.5 1.5 0 1 1 1.81-2.39L28.68 34a5.55 5.55 0 0 0 6.64 0l23.35-17.68a1.5 1.5 0 1 1 1.81 2.39L37.13 36.37A8.51 8.51 0 0 1 32 38.08Z"></path><path fill="#f9b707" d="M4.17 49.14a1.5 1.5 0 0 1-1-2.62l18.4-16.41a1.5 1.5 0 0 1 2 2.24L5.17 48.76a1.46 1.46 0 0 1-1 .38zm55.66 0a1.46 1.46 0 0 1-1-.38l-18.4-16.41a1.5 1.5 0 1 1 2-2.24l18.39 16.41a1.5 1.5 0 0 1-1 2.62z"></path></svg>
                                    </div>
                                    <div>
                                        <h5>Email</h5>
                                        <p>Support@ico.com</p>
                                    </div>
                                </div>
                                </div>
                            
                            </div>
                            <div className='col-lg-4'>
                                <div className='card'>
                                <div className='d-lg-flex details gap-2'>
                                    <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="phone" width="30" height="30"><g data-name="Out Call"><g data-name="<Group>"><path fill="none" stroke="#f9b707" strokeLinecap="round" strokeLinejoin="round" d="m13.5 10.5 6-6M19.5 8.5v-4h-4" data-name="<Path>"></path><g data-name="<Group>"><path fill="none" stroke="#f9b707" strokeLinecap="round" strokeLinejoin="round" d="M7.28 11.06a11.92 11.92 0 0 0 5.67 5.67L15.22 15a.94.94 0 0 1 1-.23 10.77 10.77 0 0 0 3.37.54 1 1 0 0 1 .94.94v3.3a1 1 0 0 1-.94.94A16.05 16.05 0 0 1 3.5 4.44a1 1 0 0 1 .94-.94h3.31a1 1 0 0 1 .94.94 10.73 10.73 0 0 0 .54 3.37 1 1 0 0 1-.24 1Z" data-name="<Path>"></path></g></g></g></svg>
                                    </div>
                                    <div>
                                        <h5>Phone</h5>
                                        <p>+9587859669</p>
                                    </div>
                                </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className='contact-form mt-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='card'>
                                    <form>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="mb-3">

                                                    <input type="text" placeholder='Full Name' className="form-control" id="exampleInputText1" aria-describedby="emailHelp" />

                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="mb-3">

                                                    <input type="text" placeholder='Last Name' className="form-control" id="exampleInputText1" aria-describedby="emailHelp" />

                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="mb-3">

                                                    <input type="text" placeholder='Mobile Number' className="form-control" id="exampleInputText1" aria-describedby="emailHelp" />

                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="mb-3">

                                                    <input type="email" placeholder='Email Address' className="form-control" id="exampleInputText1" aria-describedby="emailHelp" />

                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-outline">
                                            <textarea className="form-control textareas" id="textAreaExample1" rows="6" placeholder='Address'></textarea>

                                        </div>
                                        <div className='mt-3'>
                                            <button type='button' className='readbtn'>Send Message</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" width="100%" height="100%" frameBorder="0" ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactus