import React from 'react'

const Aboutus = () => {
    return (
        <div className='about-page py-5'>
            <div className='about-inner'>
                <div className='container'>

                    <div className='row align-items-center'>
                        <div className='col-lg-8'>
                            <h2>Aboutus</h2>
                        </div>
                        <div className='col-lg-4'>
                            <img src={process.env.PUBLIC_URL + "/images/home/faq-two.png"} className='img-fluid mt-3' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='about-section mt-5'>
                <div className='container'>
                    <h2 className='mb-2 text-center'>WHY CHOOSE OUR CURRENCY</h2>
                    <p className='text-center'>We designed a brand-new cool design and lots of features, the latest version of the template supports advanced block base scenarios, and more.</p>
                </div>
            </div>
            <div className='what-ico mt-5 mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <img src={process.env.PUBLIC_URL + "/images/home/about-img-1-2.png"} className='img-fluid mt-3' />
                        </div>
                        <div className='col-lg-7'>
                            <h2 className='mb-3'>What Is ICO?</h2>
                            <p>The world first platform to reward investors and traders build on ICO</p>
                            <div className="list-icon-box aos-init aos-animate" data-aos="fade-up" data-aos-duration={800}>
                                <div className="icon-box">
                                    <div className="icon">
                                        <span className="icon-users" /><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30" id="decentralized"><path fill="#ffff" d="M40 20a3.997 3.997 0 0 0-3.858 3H29.9a5.946 5.946 0 0 0-1.019-2.466l5.02-5.02a.921.921 0 0 0 .06-.09 4 4 0 1 0-1.384-1.384.938.938 0 0 0-.09.06l-5.02 5.02A5.946 5.946 0 0 0 25 18.1v-6.243a4 4 0 1 0-2 0V18.1a5.947 5.947 0 0 0-2.466 1.019l-5.02-5.02a.939.939 0 0 0-.09-.06 3.997 3.997 0 0 0-.596-4.868 4.094 4.094 0 0 0-5.656 0A4 4 0 0 0 12 16a3.962 3.962 0 0 0 2.041-.575.93.93 0 0 0 .06.089l5.019 5.02A5.946 5.946 0 0 0 18.1 23h-6.243a4 4 0 1 0 0 2H18.1a5.946 5.946 0 0 0 1.019 2.466l-5.02 5.02a.948.948 0 0 0-.065.099 4.104 4.104 0 0 0-4.863.587 4 4 0 1 0 6.253.788.93.93 0 0 0 .09-.06l5.019-5.02A5.947 5.947 0 0 0 23 29.9v6.243a4 4 0 1 0 2 0v-6.243a5.946 5.946 0 0 0 2.466-1.019l5.02 5.02a.938.938 0 0 0 .09.06 4 4 0 1 0 1.384-1.384.923.923 0 0 0-.06-.09l-5.02-5.02A5.946 5.946 0 0 0 29.9 25h6.243A3.993 3.993 0 1 0 40 20Zm-5.414-9.414a2 2 0 1 1 0 2.828 1.992 1.992 0 0 1 0-2.828Zm-24 2.828a2 2 0 1 1 2.828 0 2.051 2.051 0 0 1-2.828 0Zm2.828 24a2.051 2.051 0 0 1-2.828 0 2 2 0 1 1 2.828 0ZM36 34.002a1.998 1.998 0 1 1-1.414.584A1.992 1.992 0 0 1 36 34.002ZM24 28a4 4 0 1 1 4-4 4.005 4.005 0 0 1-4 4ZM22 8a2 2 0 1 1 2 2 2.002 2.002 0 0 1-2-2ZM8 26a2 2 0 1 1 2-2 2.002 2.002 0 0 1-2 2Zm18 14a2 2 0 1 1-2-2 2.002 2.002 0 0 1 2 2Zm14-14a2 2 0 1 1 2-2 2.001 2.001 0 0 1-2 2Z"></path></svg></div>
                                    <div className="content">
                                        <h5>Decentralized Platform</h5>
                                        <p className="fs-14">The platform helps investors to make easy to purchase and sell their tokens</p>
                                    </div></div><div className="icon-box"><div className="icon"><span className="icon-internet" /><svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 128 128" id="crowd" width="30" height="30"><path fill="#ffff" d="M64 48.35a12.93 12.93 0 1 0-12.89-12.93A12.91 12.91 0 0 0 64 48.35ZM64 26a9.43 9.43 0 1 1-9.39 9.42A9.42 9.42 0 0 1 64 26Zm34.46 33.28a12.93 12.93 0 1 0-12.89-12.93 12.9 12.9 0 0 0 12.89 12.93Zm0-22.36a9.43 9.43 0 1 1-9.39 9.43 9.42 9.42 0 0 1 9.39-9.43ZM107 62.39H90c-.51 0-1 0-1.54.07a17.09 17.09 0 0 0-15.92-11h-17a17.09 17.09 0 0 0-15.92 11c-.51-.05-1-.07-1.54-.07H21A17.08 17.08 0 0 0 4 79.48v20.13a5.89 5.89 0 0 0 5.88 5.89H49.2a5.89 5.89 0 0 0 5.88-5.89v-5h17.84v5a5.89 5.89 0 0 0 5.88 5.89h39.32a5.89 5.89 0 0 0 5.88-5.89V79.48a17.08 17.08 0 0 0-17-17.09ZM51.58 99.61A2.39 2.39 0 0 1 49.2 102H9.88a2.39 2.39 0 0 1-2.38-2.39V79.48A13.58 13.58 0 0 1 21 65.89h17a12.67 12.67 0 0 1 2.44.23 11.66 11.66 0 0 1 2.42.69 10.5 10.5 0 0 1 1.9.89 13.65 13.65 0 0 1 6.78 11.78v20.13Zm3.5-8.53v-11.6a17.13 17.13 0 0 0-8.52-14.8 13.67 13.67 0 0 0-2.43-1.15c-.35-.14-.71-.26-1.06-.38A13.57 13.57 0 0 1 55.5 55h17a13.57 13.57 0 0 1 12.43 8.18c-.34.11-.68.23-1 .36a14.21 14.21 0 0 0-2.47 1.16 17.14 17.14 0 0 0-8.53 14.81v11.6Zm65.42 8.53a2.39 2.39 0 0 1-2.38 2.39H78.8a2.39 2.39 0 0 1-2.38-2.39V79.48a13.63 13.63 0 0 1 6.79-11.78 10.41 10.41 0 0 1 1.94-.91 12 12 0 0 1 2.39-.68 13.12 13.12 0 0 1 2.46-.22h17a13.58 13.58 0 0 1 13.5 13.59Zm-91-40.33a12.93 12.93 0 1 0-12.84-12.93 12.91 12.91 0 0 0 12.88 12.93Zm0-22.36a9.43 9.43 0 1 1-9.38 9.43 9.42 9.42 0 0 1 9.42-9.43Z"></path></svg>

                                    </div>
                                    <div className="content">
                                        <h5>Crowd Wisdom</h5>
                                        <p className="fs-14">The process of taking into account the collective opinion of a group</p></div>
                                </div><div className="icon-box"><div className="icon"><span className="icon-users" />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30" id="decentralized"><path fill="#ffff" d="M40 20a3.997 3.997 0 0 0-3.858 3H29.9a5.946 5.946 0 0 0-1.019-2.466l5.02-5.02a.921.921 0 0 0 .06-.09 4 4 0 1 0-1.384-1.384.938.938 0 0 0-.09.06l-5.02 5.02A5.946 5.946 0 0 0 25 18.1v-6.243a4 4 0 1 0-2 0V18.1a5.947 5.947 0 0 0-2.466 1.019l-5.02-5.02a.939.939 0 0 0-.09-.06 3.997 3.997 0 0 0-.596-4.868 4.094 4.094 0 0 0-5.656 0A4 4 0 0 0 12 16a3.962 3.962 0 0 0 2.041-.575.93.93 0 0 0 .06.089l5.019 5.02A5.946 5.946 0 0 0 18.1 23h-6.243a4 4 0 1 0 0 2H18.1a5.946 5.946 0 0 0 1.019 2.466l-5.02 5.02a.948.948 0 0 0-.065.099 4.104 4.104 0 0 0-4.863.587 4 4 0 1 0 6.253.788.93.93 0 0 0 .09-.06l5.019-5.02A5.947 5.947 0 0 0 23 29.9v6.243a4 4 0 1 0 2 0v-6.243a5.946 5.946 0 0 0 2.466-1.019l5.02 5.02a.938.938 0 0 0 .09.06 4 4 0 1 0 1.384-1.384.923.923 0 0 0-.06-.09l-5.02-5.02A5.946 5.946 0 0 0 29.9 25h6.243A3.993 3.993 0 1 0 40 20Zm-5.414-9.414a2 2 0 1 1 0 2.828 1.992 1.992 0 0 1 0-2.828Zm-24 2.828a2 2 0 1 1 2.828 0 2.051 2.051 0 0 1-2.828 0Zm2.828 24a2.051 2.051 0 0 1-2.828 0 2 2 0 1 1 2.828 0ZM36 34.002a1.998 1.998 0 1 1-1.414.584A1.992 1.992 0 0 1 36 34.002ZM24 28a4 4 0 1 1 4-4 4.005 4.005 0 0 1-4 4ZM22 8a2 2 0 1 1 2 2 2.002 2.002 0 0 1-2-2ZM8 26a2 2 0 1 1 2-2 2.002 2.002 0 0 1-2 2Zm18 14a2 2 0 1 1-2-2 2.002 2.002 0 0 1 2 2Zm14-14a2 2 0 1 1 2-2 2.001 2.001 0 0 1-2 2Z"></path></svg></div>
                                    <div className="content"><h5>Decentralized Platform</h5><p className="fs-14">The platform helps investors to make easy to purchase and sell their tokens</p></div>
                                </div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='currencynumbers mb-5'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className='transactions text-center'>
                                <h2 className='mb-2'>258</h2>
                                <p>Transactions in last 24h</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='transactions text-center'>
                                <h2 className='mb-2'>258</h2>
                                <p>Transactions per hour</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='transactions text-center'>
                                <h2 className='mb-2'>258</h2>
                                <p>Largest Transactions</p>
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className='transactions text-center'>
                                <h2 className='mb-2'>258</h2>
                                <p>Years of Experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ico-currencies'>
                <div className='container'>
                    <h2 className='mb-3 text-center'>What Is ICO Crypto-Currencies?</h2>
                    <p className='text-center'>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour</p>
                    <div className='row mt-5'>
                        <div className='col-lg-4 mb-2'>
                            <div className='Safe'>
                                <div className=''><img src={process.env.PUBLIC_URL + "/images/home/service_icon1.png"} className='img-fluid ' /></div>
                                <h5 className='mb-2 text-center'>Planning</h5>
                                <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                    currency!</p>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'>
                            <div className='Safe'>
                                <div className=''> <img src={process.env.PUBLIC_URL + "/images/home/service_icon2.png"} className='img-fluid ' /></div>
                                <h5 className='mb-2 text-center'>Credit Card Use
                                </h5>
                                <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                    currency!</p>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'>
                            <div className='Safe'>
                                <div className=''><img src={process.env.PUBLIC_URL + "/images/home/service_icon3.png"} className='img-fluid ' /></div>
                                <h5 className='mb-2 text-center'>Investment projects
                                </h5>
                                <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                    currency!</p>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-lg-4 mb-2'>
                            <div className='Safe'>
                                <div className=''><img src={process.env.PUBLIC_URL + "/images/home/service_icon4.png"} className='img-fluid ' /></div>
                                <h5 className='mb-2 text-center'>Mobile App
                                </h5>
                                <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                    currency!</p>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'>
                            <div className='Safe'>
                                <div className=''><img src={process.env.PUBLIC_URL + "/images/home/service_icon5.png"} className='img-fluid ' /></div>
                                <h5 className='mb-2 text-center'>Exchange Service
                                </h5>
                                <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                    currency!</p>
                            </div>
                        </div>
                        <div className='col-lg-4 mb-2'>
                            <div className='Safe'>
                                <div className=''><img src={process.env.PUBLIC_URL + "/images/home/service_icon6.png"} className='img-fluid ' /></div>
                                <h5 className='mb-2 text-center'>Several Profit  </h5>
                                <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                    currency!</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='about-section mt-5 mb-5'>
                <div className='container'>
                    <div className='row align-items-center'>

                        <div className='col-lg-6'>
                            <h6 className='mb-2'>About ICO Crypto Trading</h6>

                            <h2 className='mb-3'>Bitcoin is an innovative payment network and a new kind of money</h2>
                            <p className='mb-2'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore.</p>
                            <p>
                                About Us
                                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore.

                            </p>

                            <button type='button' className='readbtn btn'>Readmore</button>
                        </div>
                        <div className='col-lg-6'>
                            <img src={process.env.PUBLIC_URL + "/images/home/banner_img2.png"} className='img-fluid' />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Aboutus