import React, { Component } from "react";
import { redirect } from "react-router";
import { LoginApiCall } from "../../../QAZXSWEDC/ApiFunction";
import toast from "react-hot-toast";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.connect = this.connect.bind(this);
    this.removeToken = this.removeToken.bind(this)
    this.state = {
      Token: localStorage.getItem("MetaMaskToken") || ""
    }

  }

  async connect() {
    try {
      if (window.ethereum) {
        const provider = window.ethereum;
        console.log(provider)
        let sss = await provider.request({ method: "eth_requestAccounts", params: [{}] });
console.log(sss)
        let Response = await LoginApiCall({ MetaMaskToken: sss[0] })

        if (Response) {
          localStorage.setItem("MetaMaskToken", sss[0])

          this.setState({ Token: sss[0] })
  
          window.location.href = "/dashboard"
        }
        else{
          toast.dismiss()
          toast.error(<div dangerouslySetInnerHTML={{ __html: "first Add Meta Mask extension in Your Browser" }} />);
          console.error("MetaMask extention not found!!!");
        }
      
      } else { 
        toast.dismiss()
        toast.error(<div dangerouslySetInnerHTML={{ __html: "first Add Meta Mask extension in Your Browser" }} />);
        console.error("MetaMask extention not found!!!");
      }
    } catch (err) {
      console.error("Failed to connect:", err);
    }
  }

  async removeToken() {
    try {
      const provider = window.ethereum;
      let sss = await provider.request({ method: "wallet_revokePermissions", params: [{ "eth_accounts": {} }] });
      localStorage.removeItem("MetaMaskToken")
      window.location.href = ""
    } catch (error) {
      console.error("Logout Error :",error);
    }
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="bannercontent">
            <div className="d-flex gap-3 conectbtns ">
              {!!this.state.Token ? <button type="button" className="btn" onClick={this.removeToken} style={{ marginLeft: "17px" }}> Logout </button> :
                <button type="button" className="btn" onClick={this.connect} style={{ marginLeft: "17px" }}> Connect </button>}
            </div>
          </div>
        </header>
      </div>
    );
  }
}
