import React from 'react'

const Services = () => {
    return (
        <div className='about-page py-5'>
            <div className='about-inner'>
                <div className='container'>

                    <div className='row align-items-center'>
                        <div className='col-lg-8'>
                            <h2>Services</h2>
                        </div>
                        <div className='col-lg-4 mt-4'>
                            <img src={process.env.PUBLIC_URL + "/images/home/features-of-ico-development-service.png"} className='img-fluid mt-3' />
                        </div>
                    </div>
                </div>
            </div>
            <div class="me-about me-about-single">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="me-about-img">
                            <img src={process.env.PUBLIC_URL + "/images/home/ico_banner.png"} className='img-fluid mt-3' />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="me-heading">
                                <h4>Lorem ipsum doler</h4>
                                <h1>Legal Compliance
                                </h1>
                            </div>
                            <div class="me-about-text">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="me-counter">
                <div class="me-counter-heading">
                    <h1>Why we are the best for you</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    <p>sed do eiusmod tempor incididunt ut labore</p>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="me-counter-box" id="counter">
                                <ul>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <g>
                                                <path d="M108.512,275.939c4.15,0,7.515-3.364,7.515-7.515v-5.21c0-11.984-9.157-21.87-20.841-23.03v-8.231
                                            c0-4.151-3.365-7.515-7.515-7.515c-4.15,0-7.515,3.364-7.515,7.515v8.591c-11.858,2.299-20.841,12.756-20.841,25.275
                                            c0,14.199,11.552,25.75,25.75,25.75h5.21c5.912,0,10.721,4.809,10.721,10.721s-4.809,10.721-10.721,10.721h-7.815
                                            c-4.475,0-8.116-3.641-8.116-8.117c0-4.151-3.365-7.515-7.515-7.515c-4.15,0-7.515,3.364-7.515,7.515
                                            c0,11.984,9.156,21.87,20.841,23.03v8.231c0,4.151,3.365,7.515,7.515,7.515c4.15,0,7.515-3.364,7.515-7.515v-8.591
                                            c11.858-2.299,20.841-12.756,20.841-25.275c0-14.199-11.552-25.75-25.75-25.75h-5.21c-5.912,0-10.721-4.809-10.721-10.721
                                            s4.809-10.721,10.721-10.721h7.815c4.475,0,8.116,3.641,8.116,8.117v5.21C100.997,272.575,104.362,275.939,108.512,275.939z"></path>
                                            </g>
                                            <g>
                                                <path d="M276.841,83.563c4.151,0,7.515-3.364,7.515-7.515v-5.21c0-11.984-9.157-21.87-20.841-23.03v-8.231
                                            c0-4.151-3.364-7.515-7.515-7.515c-4.15,0-7.515,3.364-7.515,7.515v8.591c-11.858,2.299-20.841,12.756-20.841,25.275
                                            c0,14.199,11.552,25.75,25.75,25.75h5.21c5.912,0,10.721,4.809,10.721,10.721s-4.809,10.721-10.721,10.721h-7.815
                                            c-4.475,0-8.116-3.641-8.116-8.117c0-4.151-3.365-7.515-7.515-7.515s-7.515,3.364-7.515,7.515c0,11.984,9.156,21.87,20.841,23.03
                                            v8.231c0,4.151,3.365,7.515,7.515,7.515c4.151,0,7.515-3.364,7.515-7.515v-8.591c11.857-2.299,20.841-12.756,20.841-25.275
                                            c0-14.199-11.552-25.75-25.75-25.75h-5.21c-5.912,0-10.721-4.809-10.721-10.721s4.809-10.721,10.721-10.721h7.815
                                            c4.475,0,8.116,3.641,8.116,8.117v5.21C269.326,80.2,272.69,83.563,276.841,83.563z"></path>
                                            </g>
                                            <g>
                                                <path d="M424.329,148.29c-21.722,0-41.622,7.945-56.954,21.076l-37.201-31.001c8.545-13.527,13.497-29.538,13.497-46.686
                                            c0-48.342-39.329-87.671-87.671-87.671s-87.671,39.329-87.671,87.671c0,25.365,10.831,48.243,28.107,64.266l-61.262,54.455
                                            c-13.697-8.864-30.009-14.017-47.503-14.017C39.329,196.384,0,235.712,0,284.055s39.329,87.671,87.671,87.671
                                            c48.342,0,87.671-39.329,87.671-87.671c0-25.365-10.831-48.243-28.107-64.267l61.262-54.455
                                            c13.697,8.864,30.009,14.017,47.503,14.017c25.798,0,49.027-11.203,65.084-28.996l35.713,29.76
                                            c-12.573,15.177-20.139,34.644-20.139,55.846c0,48.342,39.329,87.671,87.671,87.671c48.342,0,87.671-39.329,87.671-87.671
                                            S472.671,148.29,424.329,148.29z M160.313,284.055c0,40.055-32.587,72.642-72.642,72.642s-72.642-32.587-72.642-72.642
                                            c0-40.055,32.587-72.642,72.642-72.642C127.726,211.413,160.313,244,160.313,284.055z M256,164.321
                                            c-40.055,0-72.642-32.587-72.642-72.642S215.945,19.037,256,19.037s72.642,32.587,72.642,72.642S296.055,164.321,256,164.321z
                                             M424.329,308.603c-40.055,0-72.642-32.587-72.642-72.642c0-18.9,7.26-36.133,19.132-49.068c1.195-0.44,2.298-1.182,3.173-2.231
                                            c0.565-0.678,0.982-1.43,1.271-2.213c12.935-11.869,30.166-19.129,49.065-19.129c40.055,0,72.642,32.587,72.642,72.642
                                            C496.971,276.016,464.384,308.603,424.329,308.603z"></path>
                                            </g>
                                            <g>
                                                <path d="M445.169,227.845c4.151,0,7.515-3.364,7.515-7.515v-5.21c0-11.984-9.157-21.87-20.841-23.03v-8.231
                                            c0-4.151-3.364-7.515-7.515-7.515c-4.151,0-7.515,3.364-7.515,7.515v8.591c-11.857,2.299-20.841,12.756-20.841,25.275
                                            c0,14.199,11.552,25.75,25.75,25.75h5.209c5.912,0,10.721,4.809,10.721,10.721c0,5.912-4.809,10.721-10.721,10.721h-7.815
                                            c-4.475,0-8.116-3.641-8.116-8.117c0-4.151-3.364-7.515-7.515-7.515c-4.151,0-7.515,3.364-7.515,7.515
                                            c0,11.984,9.156,21.87,20.841,23.03v8.231c0,4.151,3.364,7.515,7.515,7.515c4.151,0,7.515-3.364,7.515-7.515v-8.591
                                            c11.857-2.299,20.841-12.756,20.841-25.275c0-14.199-11.552-25.75-25.75-25.75h-5.209c-5.912,0-10.721-4.809-10.721-10.721
                                            s4.809-10.721,10.721-10.721h7.815c4.475,0,8.116,3.641,8.116,8.117v5.21C437.655,224.481,441.018,227.845,445.169,227.845z"></path>
                                            </g>
                                            <g>
                                                <path d="M159.812,396.775H95.687c-4.15,0-7.515,3.364-7.515,7.515v96.188c0,4.151,3.365,7.515,7.515,7.515h64.125
                                            c4.15,0,7.515-3.364,7.515-7.515V404.29C167.327,400.138,163.962,396.775,159.812,396.775z M152.297,492.963h-49.096v-81.159
                                            h49.096V492.963z"></path>
                                            </g>
                                            <g>
                                                <path d="M472.423,396.775h-64.125c-4.151,0-7.515,3.364-7.515,7.515v96.188c0,4.151,3.364,7.515,7.515,7.515h64.125
                                            c4.151,0,7.515-3.364,7.515-7.515V404.29C479.937,400.138,476.574,396.775,472.423,396.775z M464.908,492.963h-49.096v-81.159
                                            h49.096V492.963z"></path>
                                            </g>
                                            <g>
                                                <path d="M264.016,428.34c4.151,0,7.515-3.364,7.515-7.515V308.102c0-4.151-3.364-7.515-7.515-7.515H199.89
                                            c-4.15,0-7.515,3.364-7.515,7.515v192.376c0,4.151,3.365,7.515,7.515,7.515h64.125c4.151,0,7.515-3.364,7.515-7.515v-44.585
                                            c0-4.151-3.364-7.515-7.515-7.515c-4.151,0-7.515,3.364-7.515,7.515v37.07h-49.096V315.616h49.096v105.208
                                            C256.501,424.976,259.865,428.34,264.016,428.34z"></path>
                                            </g>
                                            <g>
                                                <path d="M368.219,348.681h-64.125c-4.151,0-7.515,3.364-7.515,7.515v144.282c0,4.151,3.364,7.515,7.515,7.515h64.125
                                            c4.151,0,7.515-3.364,7.515-7.515V356.196C375.734,352.045,372.37,348.681,368.219,348.681z M360.705,492.963h-49.096V363.71
                                            h49.096V492.963z"></path>
                                            </g>
                                        </svg>
                                        <div class="me-counter-no">
                                            <h4 class="counter-value" data-count="15">0</h4>
                                            <p>Year of business</p>
                                        </div>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.544 489.544">
                                            <g>
                                                <path d="M138.943,205.941c-7-7.1-18.4-7.1-25.5-0.1c-7.1,7-7.1,18.4-0.1,25.5l85.4,86c3.4,3.4,7.9,5.3,12.7,5.3c0,0,0,0,0.1,0
                                            c4.8,0,9.3-1.9,12.7-5.2l260-258.6c7-7,7.1-18.4,0.1-25.5s-18.4-7.1-25.5-0.1l-91.9,91.3c-39.1-48.1-97.9-76.3-160.4-76.3
                                            c-29.2,0-57.4,6-83.8,17.7c-25.5,11.4-48.2,27.5-67.3,48.1c-19.1,20.5-33.6,44.2-43.1,70.4c-9.9,27.3-13.9,56-11.8,85.4
                                            c7.1,101.7,89.2,183.9,190.9,191.2c5,0.4,10.1,0.5,15.1,0.5c47.3,0,93-16,129.8-45.8c40.2-32.4,66.6-78.3,74.5-129.1
                                            c1.6-10.4,2.4-21.1,2.4-31.8c0-9.9-8.1-18-18-18c-9.9,0-18,8.1-18,18c0,8.8-0.7,17.6-2,26.3c-13.4,87-93,150.2-181.2,143.9
                                            c-83.9-6-151.7-73.8-157.6-157.8c-3.4-48,12.7-93.7,45.3-128.7c32.2-34.5,77.7-54.3,124.8-54.3c52.8,0,102.4,24.5,134.7,65.9
                                            l-129.6,129L138.943,205.941z"/>
                                            </g>
                                        </svg>
                                        <div class="me-counter-no">
                                            <h4 class="counter-value" data-count="100">0</h4><span> %</span>
                                            <p>Successful transaction</p>
                                        </div>
                                    </li>
                                    <li>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 482.9 482.9">
                                            <g>
                                                <path d="M239.7,260.2c0.5,0,1,0,1.6,0c0.2,0,0.4,0,0.6,0c0.3,0,0.7,0,1,0c29.3-0.5,53-10.8,70.5-30.5
                                            c38.5-43.4,32.1-117.8,31.4-124.9c-2.5-53.3-27.7-78.8-48.5-90.7C280.8,5.2,262.7,0.4,242.5,0h-0.7c-0.1,0-0.3,0-0.4,0h-0.6
                                            c-11.1,0-32.9,1.8-53.8,13.7c-21,11.9-46.6,37.4-49.1,91.1c-0.7,7.1-7.1,81.5,31.4,124.9C186.7,249.4,210.4,259.7,239.7,260.2z
                                             M164.6,107.3c0-0.3,0.1-0.6,0.1-0.8c3.3-71.7,54.2-79.4,76-79.4h0.4c0.2,0,0.5,0,0.8,0c27,0.6,72.9,11.6,76,79.4
                                            c0,0.3,0,0.6,0.1,0.8c0.1,0.7,7.1,68.7-24.7,104.5c-12.6,14.2-29.4,21.2-51.5,21.4c-0.2,0-0.3,0-0.5,0l0,0c-0.2,0-0.3,0-0.5,0
                                            c-22-0.2-38.9-7.2-51.4-21.4C157.7,176.2,164.5,107.9,164.6,107.3z"/>
                                                <path d="M446.8,383.6c0-0.1,0-0.2,0-0.3c0-0.8-0.1-1.6-0.1-2.5c-0.6-19.8-1.9-66.1-45.3-80.9c-0.3-0.1-0.7-0.2-1-0.3
                                            c-45.1-11.5-82.6-37.5-83-37.8c-6.1-4.3-14.5-2.8-18.8,3.3c-4.3,6.1-2.8,14.5,3.3,18.8c1.7,1.2,41.5,28.9,91.3,41.7
                                            c23.3,8.3,25.9,33.2,26.6,56c0,0.9,0,1.7,0.1,2.5c0.1,9-0.5,22.9-2.1,30.9c-16.2,9.2-79.7,41-176.3,41
                                            c-96.2,0-160.1-31.9-176.4-41.1c-1.6-8-2.3-21.9-2.1-30.9c0-0.8,0.1-1.6,0.1-2.5c0.7-22.8,3.3-47.7,26.6-56
                                            c49.8-12.8,89.6-40.6,91.3-41.7c6.1-4.3,7.6-12.7,3.3-18.8c-4.3-6.1-12.7-7.6-18.8-3.3c-0.4,0.3-37.7,26.3-83,37.8
                                            c-0.4,0.1-0.7,0.2-1,0.3c-43.4,14.9-44.7,61.2-45.3,80.9c0,0.9,0,1.7-0.1,2.5c0,0.1,0,0.2,0,0.3c-0.1,5.2-0.2,31.9,5.1,45.3
                                            c1,2.6,2.8,4.8,5.2,6.3c3,2,74.9,47.8,195.2,47.8s192.2-45.9,195.2-47.8c2.3-1.5,4.2-3.7,5.2-6.3
                                            C447,415.5,446.9,388.8,446.8,383.6z"/>
                                            </g>
                                        </svg>
                                        <div class="me-counter-no">
                                            <h4 class="counter-value" data-count="750">0</h4>
                                            <p>Member since year</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='readystart mt-5'>
                <div className='container'>
                    <div className='getcontent text-center mb-4'>

                        <h2 className='mb-3 mt-3'>Cross-Platform Trading</h2>
                        <p className=''>Can’t wait to try ICO Crypto? You don’t have to! Getting started is a matter of minutes.</p>
                    </div>

                    <div className='row mt-3'>
                        <div className='col-lg-4'>
                            <div className='d-lg-flex align-items-center getstartim gap-3'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/images/home/createwallet.png"} className='img-fluid mb-3' />
                                </div>
                                <div>
                                    <h5 className='mb-2'>Interest-free exchange
                                    </h5>
                                    <p >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos consectetur tidio.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-lg-flex align-items-center getstartim gap-3'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/images/home/makemoney.png"} className='img-fluid mb-3' />
                                </div>
                                <div>
                                    <h5 className='mb-2'>Quick withdrawal
                                    </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos consectetur tidio.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='d-lg-flex align-items-center getstartim gap-3'>
                                <div>
                                    <img src={process.env.PUBLIC_URL + "/images/home/buysell.png"} className='img-fluid mb-3' />
                                </div>
                                <div>
                                    <h5 className='mb-2'>Scam protection
                                    </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos consectetur tidio.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Services