import ApiCall from "../../QAZXSWEDC/ApiCall"

export const onModalOpen = (val) => (dispatch) => {
    dispatch({
        type: 'MODAL_OPEN',
        payload: val
    })
}
export const onChangeDarkMode = (val) => (dispatch) => {

    if (val === 'dark') {
        window.document.children[0].setAttribute('data-theme', 'dark');
        localStorage.setItem('data-theme', 'dark')
    } else if (val === 'high-contrast') {
    } else {
        window.document.children[0].setAttribute('data-theme', 'light')
        localStorage.setItem('data-theme', 'light')
    }
    dispatch({
        type: 'DARK_MODE',
        payload: val
    })
    dispatch({
        type: 'HIGH_CONTRAST',
        payload: val
    })
}
export const onChangeHighcontrast = (val) => (dispatch) => {

    if (val === 'high-contrast') {
        window.document.children[0].setAttribute('data-theme', 'high-contrast');

    } else if (val === 'dark') {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    else {
        window.document.children[0].setAttribute('data-theme', 'light')
    }
    dispatch({
        type: 'HIGH_CONTRAST',
        payload: val
    })
    dispatch({
        type: 'DARK_MODE',
        payload: val
    })
}

export const OnchangeRTLmode = (val) => (dispatch) => {

    if (document.body.classList.contains("rtl_mode")) {
        document.body.classList.remove("rtl_mode")
    } else {
        document.body.classList.add("rtl_mode");
    }

    dispatch({
        type: 'rtl_mode',
        payload: val
    })
}

export const onMenuData = (val) => (dispatch) => {
    dispatch({
        type: 'MENUDATA',
        payload: val
    })
}


export const UpdateUserprofileimg = (val) => (dispatch) => {
    dispatch({
        type: 'PROFILE_IMG',
        payload: val
    })
}

export const GetTradePairData = (val) => (dispatch) => {
    dispatch({
        type: 'PAIR_DATA',
        payload: val
    })
}

export const ChoosenExchangePrice = (price, amount, type) => (dispatch) => {

    let object = { buyPrice: '', buyAmount: '', sellPrice: price, sellAmount: amount, tab: 2 };

    if (type === 'sell') {

        object = { sellPrice: '', sellAmount: '', buyPrice: price, buyAmount: amount, tab: 1 };
    }

    dispatch({ type: 'CHOOSEN_EXCHANGE_PRICE', payload: object })
}

export const tradeHistory = (val) => (dispatch) => {

    dispatch({ type: 'SET_TRADE_HISTORY', payload: val })
}

export const AllPairsocketdata = (val) => (dispatch) => {
    dispatch({ type: 'ALL_PAIR_SOCKET_DATA', payload: val })
}

export const Notifications = (val) => async (dispatch) => {

    const getResponse = await ApiCall.ApiHelper("notification", {}, 'POST', 'user')

    dispatch({ type: 'All_Notification', payload: getResponse })
}

export const CurrentLastPriceUpdate = (val) => (dispatch) => {
    dispatch({ type: 'CURRENT_MARKET_PRICE', payload: val })
}


