import ApiCall from "./ApiCall"


export const LoginApiCall = async (data) => {
    const getResponse = await ApiCall.ApiHelper("MetaMaskConnect", data, 'POST', 'all')
    return getResponse;
}


export const DepositeCurrency = async (data) => {
    const getResponse = await ApiCall.ApiHelper("depositeCurrency", data, 'POST', 'all')
    return getResponse;
}

export const GEtBalance = async (data) => {
    const getResponse = await ApiCall.ApiHelper("GetBalance", data, 'POST', 'all')
    return getResponse;
}

export const CreateDeposite = async (data) => {
    const getResponse = await ApiCall.ApiHelper("createDeposite", data, 'POST', 'all')
    return getResponse;
}


export const WIthdrawCreateRequest = async (data) => {
    const getResponse = await ApiCall.ApiHelper("WithdrawCreateRequest", data, 'POST', 'all')
    return getResponse;
}

export const userwallet = async (data) => {
    const getResponse = await ApiCall.ApiHelper("userwallet", data, 'POST', 'all')
    return getResponse;
}

export const WIthdrawHistory = async (data) => {
    const getResponse = await ApiCall.ApiHelper("withdrawHistory", data, 'POST', 'all')
    return getResponse;
}

export const DEpositeHistory = async (data) => {
    const getResponse = await ApiCall.ApiHelper("DepositeHistory", data, 'POST', 'all')
    return getResponse;
}

export const TradePairs = async (data) => {
    const getResponse = await ApiCall.ApiHelper("allPairs", data, 'POST', 'exchange')
    return getResponse;
}



export const TradePairsHistory = async (data) => {
    const getResponse = await ApiCall.ApiHelper("userHistories", data, 'POST', 'exchange')
    return getResponse;
}


export const userTradeHistory = async (data) => {
    const getResponse = await ApiCall.ApiHelper("userHistories", data, 'POST', 'all')
    return getResponse;
}

export const GetpairData = async (data, signal) => {
    const getResponse = await ApiCall.ApiHelper("pairData", data, 'POST', 'exchange', signal)
    return getResponse;
}

export const MakeBuysell = async (data) => {
    const getResponse = await ApiCall.ApiHelper("makeOrder", data, 'POST', 'exchange')
    return getResponse;
}

export const OpenOrdersHistory = async (data, signal) => {
    const getResponse = await ApiCall.ApiHelper("openOrder", data, 'POST', 'exchange', signal)
    return getResponse;
}

export const CancelOpenOrder = async (data) => {
    const getResponse = await ApiCall.ApiHelper("cancelLiqOrder", data, 'POST', 'exchange')
    return getResponse;
}


export const UpdateFavPair = async (data) => {
    const getResponse = await ApiCall.ApiHelper("updateFav", data, 'POST', 'exchange')
    return getResponse;
}

export const MarketApi = async (data, signal) => {
    const getResponse = await ApiCall.ApiHelper("alltradePair", data, 'POST', 'all', signal)
    return getResponse;
}

export const MarketApiMarket = async (data) => {
    const getResponse = await ApiCall.ApiHelper("marketprice", {}, 'POST', 'all')
    return getResponse;
}

export const TickerLastPrice = async (data) => {
    const getResponse = await ApiCall.ApiHelper("lastPrice", {}, 'POST', 'all')
    return getResponse;
}




