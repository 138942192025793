import React from 'react'
import Hero from './Hero'

const Home = () => {
    return (
        <div>
            <div className='ico-home py-5'>

                <Hero />
                <section className="features mt-5 mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 mb-2">
                                <div className="single-feature">
                                    <div className="left">
                                        <img src={process.env.PUBLIC_URL + "/images/home/feat1.png"} className='img-fluid ' />						</div>
                                    <div className="right">
                                        <p className="sub-title">
                                            Yearly Interest
                                        </p>
                                        <h4 className="title">
                                            38%
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-2">
                                <div className="single-feature">
                                    <div className="left">
                                        <img src={process.env.PUBLIC_URL + "/images/home/feat2.png"} className='img-fluid ' />						</div>
                                    <div className="right">
                                        <p className="sub-title">
                                            Loan Duration
                                        </p>
                                        <h4 className="title">
                                            0-12Mo
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mb-2">
                                <div className="single-feature">
                                    <div className="left">
                                        <img src={process.env.PUBLIC_URL + "/images/home/feat3.png"} className='img-fluid ' />						</div>
                                    <div className="right">
                                        <p className="sub-title">
                                            Fee as low as
                                        </p>
                                        <h4 className="title">
                                            60%
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='about-section mt-5 mb-5'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <img src={process.env.PUBLIC_URL + "/images/home/heroright2.png"} className='img-fluid' />
                            </div>
                            <div className='col-lg-6'>
                                <h6 className='mb-2'>About ICO Crypto Trading</h6>

                                <h2 className='mb-3'>Leading Blockchain, Crypto & ICO Development Services</h2>
                                <p className='mb-2'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore.</p>
                                <p>
                                    About Us
                                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed dianonumy eirmod tempor invidunt ut labore.

                                </p>

                                <button type='button' className='readbtn btn'>Readmore</button>
                            </div>

                        </div>
                    </div>

                </div>

                <div className='feature-sec'>
                    <div className='container'>
                        <h2 className='mb-3 text-center'>Our Awesome Features</h2>

                        <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                            currency!</p>
                        <div className='row mt-5'>
                            <div className='col-lg-4 mb-2'>
                                <div className='Safe'>
                                    <div className='feature'><img src={process.env.PUBLIC_URL + "/images/home/feature-1.png"} className='img-fluid ' /></div>
                                    <h5 className='mb-2 text-center'>Safe & Secure</h5>
                                    <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                        currency!</p>
                                </div>
                            </div>
                            <div className='col-lg-4 mb-2'>
                                <div className='Safe'>
                                    <div className='feature'> <img src={process.env.PUBLIC_URL + "/images/home/feature-2.png"} className='img-fluid ' /></div>
                                    <h5 className='mb-2 text-center'>Early Bonus</h5>
                                    <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                        currency!</p>
                                </div>
                            </div>
                            <div className='col-lg-4 mb-2'>
                                <div className='Safe'>
                                    <div className='feature'><img src={process.env.PUBLIC_URL + "/images/home/feature-3.png"} className='img-fluid ' /></div>
                                    <h5 className='mb-2 text-center'>Univarsal Access</h5>
                                    <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                        currency!</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-lg-4 mb-2'>
                                <div className='Safe'>
                                    <div className='feature'><img src={process.env.PUBLIC_URL + "/images/home/feature-4.png"} className='img-fluid ' /></div>
                                    <h5 className='mb-2 text-center'>Secure Storage</h5>
                                    <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                        currency!</p>
                                </div>
                            </div>
                            <div className='col-lg-4 mb-2'>
                                <div className='Safe'>
                                    <div className='feature'><img src={process.env.PUBLIC_URL + "/images/home/feature-5.png"} className='img-fluid ' /></div>
                                    <h5 className='mb-2 text-center'>Low Cost</h5>
                                    <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                        currency!</p>
                                </div>
                            </div>
                            <div className='col-lg-4 mb-2'>
                                <div className='Safe'>
                                    <div className='feature'><img src={process.env.PUBLIC_URL + "/images/home/feature-6.png"} className='img-fluid ' /></div>
                                    <h5 className='mb-2 text-center'>Several Profit  </h5>
                                    <p className='text-center'>Hey guys, just a quick update on exchange orders. There have been some changes
                                        currency!</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='wychoosec mt-5'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-7'>
                                <h6 className='mb-2'>WHY CHOOSE US?</h6>
                                <h2 className='mb-3'>Enterprise Blockchain Services to Build a Better Future.</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <ul className="features-list">
                                    <li> <span>Payment options</span></li>
                                    <li> <span>Strong security</span></li>
                                    <li> <span>Competitive commissions</span></li>
                                    <li> <span>24/7 support</span></li>
                                    <li> <span>Reliable order execution</span></li>
                                    <li> <span>Range of API solutions</span></li>
                                </ul>

                            </div>
                            <div className='col-lg-5'>
                                <img src={process.env.PUBLIC_URL + "/images/home/whychooseimg.png"} className='img-fluid mb-3' />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="invest-area bg-color area-padding-2">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="section-headline text-center">
                                    <h3>The best investment plan</h3>
                                    <p>Help agencies to define their new business objectives and then create professional software.</p>
                                </div>
                            </div>
                        </div>
                        <div class="pricing-content">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-lg-3">
                                    <div class="pri_table_list">
                                        <div class="top-price-inner">
                                            <div class="rates">
                                                <span class="prices">5%</span><span class="users">Daily</span>
                                            </div>
                                            <span class="per-day">10 days</span>
                                        </div>
                                        <ol class="pricing-text">
                                            <li class="check">Minimam Invest : $100</li>
                                            <li class="check">Maximam Invest : $1000</li>
                                            <li class="check">Avarage Monthly : 50% </li>
                                        </ol>
                                        <div class="price-btn blue">
                                            <a class="blue" href="#">Deposite</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-3">
                                    <div class="pri_table_list">
                                        <div class="top-price-inner">
                                            <div class="rates">
                                                <span class="prices">15%</span><span class="users">Daily</span>
                                            </div>
                                            <span class="per-day">30 days</span>
                                        </div>
                                        <ol class="pricing-text">
                                            <li class="check">Minimam Invest : $1000</li>
                                            <li class="check">Maximam Invest : $10000</li>
                                            <li class="check">Avarage Monthly :100% </li>
                                        </ol>
                                        <div class="price-btn blue">
                                            <a class="blue" href="#">Deposite</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-3">
                                    <div class="pri_table_list">
                                        <div class="top-price-inner">
                                            {/* <span class="base">Popular</span> */}
                                            <div class="rates">
                                                <span class="prices">50%</span><span class="users">Daily</span>
                                            </div>
                                            <span class="per-day">45 days</span>
                                        </div>
                                        <ol class="pricing-text">
                                            <li class="check">Minimam Invest : $1000</li>
                                            <li class="check">Maximam Invest : $50000</li>
                                            <li class="check">Avarage Monthly : 200% </li>
                                        </ol>
                                        <div class="price-btn blue">
                                            <a class="blue" href="#">Deposite</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-lg-3">
                                    <div class="pri_table_list">
                                        {/* <span class="base">Best sale</span> */}
                                        <div class="top-price-inner">
                                            <div class="rates">
                                                <span class="prices">100%</span><span class="users">Daily</span>
                                            </div>
                                            <span class="per-day">60 days</span>
                                        </div>
                                        <ol class="pricing-text">
                                            <li class="check">Minimam Invest : $1000</li>
                                            <li class="check">Maximam Invest : $50000</li>
                                            <li class="check">Avarage Monthly : 250% </li>
                                        </ol>
                                        <div class="price-btn blue">
                                            <a class="blue" href="#">Deposite</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ico-crypto mt-5">
                    <div className='container'>
                        <h2 className='mb-3'>Know more about ICO Crypto</h2>
                        <div className='row mt-4'>
                            <div className='col-lg-6'>
                                <div className='card'>
                                    <div className='d-lg-flex  gap-3 cryptofunds align-items-center'>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/images/home/makemoney.png"} className='img-fluid mb-3' />
                                        </div>
                                        <div>
                                            <h5 className="mb-2">complaince</h5>
                                            <p>We believe legal compliance is key to growing digital finance.</p>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-lg-6'>
                                <div className='card'>
                                    <div className='d-lg-flex cryptofunds align-items-center gap-3'>
                                        <div>
                                            <img src={process.env.PUBLIC_URL + "/images/home/makemoney.png"} className='img-fluid mb-3' />
                                        </div>
                                        <div>
                                            <h5 className="mb-2">Funds management</h5>
                                            <p>ICO Cryptosimplifies the lorem ipsum dolor sumit in repeherenderit.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='readystart mt-2 mb-5'>
                    <div className='container'>
                        <div className='getcontent text-center mb-4'>

                            <h2 className='mb-3 mt-3'>Get started now</h2>
                            <p className=''>Can’t wait to try ICO Crypto? You don’t have to! Getting started is a matter of minutes.</p>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-lg-4'>
                                <div className='d-lg-flex align-items-center getstartim gap-3'>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/images/home/createwallet.png"} className='img-fluid mb-3' />
                                    </div>
                                    <div>
                                        <h5 className='mb-2'>Create Your Wallet</h5>
                                        <p >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos consectetur tidio.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-lg-flex align-items-center getstartim gap-3'>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/images/home/makemoney.png"} className='img-fluid mb-3' />
                                    </div>
                                    <div>
                                        <h5 className='mb-2'>Make Payment</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos consectetur tidio.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='d-lg-flex align-items-center getstartim gap-3'>
                                    <div>
                                        <img src={process.env.PUBLIC_URL + "/images/home/buysell.png"} className='img-fluid mb-3' />
                                    </div>
                                    <div>
                                        <h5 className='mb-2'>Buy & Sell Coins</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos consectetur tidio.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div> */}

                <div className='Token-Details  mb-4'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8'>
                            <h6 className='mb-2'>Details</h6>
                                <h2 className='mb-3'>ICO Token Details</h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                <div className='row mt-4'>
                                    <div className='col-lg-4'>
                                        <div className='card align-items-start getstartim gap-3 p-3'>
                                            <div>
                                                <h5 className='mb-2'>Start</h5>
                                                <p>Aug 8, 2021 (9:00AM GMT)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='card align-items-start getstartim gap-3 p-3'>
                                            <div>
                                                <h5 className='mb-2'>End</h5>
                                                <p>Feb 10, 2022 (9:00AM GMT)</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='card align-items-start getstartim gap-3 p-3'>
                                            <div>
                                                <h5 className='mb-2'>Number Of Tokens For Sale</h5>
                                                <p>1.000.000 Tokens</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-lg-4'>
                                        <div className='card align-items-start getstartim gap-3 p-3'>
                                            <div>
                                                <h5 className='mb-2'>Tokens Exchange Rate</h5>
                                                <p>1 ETH = 650 ICC, 1 BTC = 1940 ICC</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='card align-items-start getstartim gap-3 p-3'>
                                            <div>
                                                <h5 className='mb-2'>Minimal Transaction</h5>
                                                <p>10 Tokens / Transaction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='card align-items-start getstartim gap-3 p-3'>
                                            <div>
                                                <h5 className='mb-2'>Acceptable Curencies</h5>
                                                <p>ETH, BTC, LTC</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                            <img src={process.env.PUBLIC_URL + "/images/home/ido_deve.png"} className='img-fluid mb-3' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Home