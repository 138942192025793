const helpers = {

	APIURL: function(){
		const URL = process.env.REACT_APP_USERURL
		return URL;
	},

	EXCHANGEURL: function(){
		const URL = process.env.REACT_APP_EXGURL
		return URL;
	},

}

export default helpers;