import React from 'react'
import './PreLoader.css'

const Loader = () => {
  return (
    <div className="preloader" >

      <div className="loading__icon">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          viewBox="0 0 50.3 50.3" enableBackground="new 0 0 50.3 50.3" >
          <path className="loading__element" fill="#4853bb" d="M25.1,0c-1.3,0-2.3,1-2.3,2.3v9.1c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3V2.3C27.4,1,26.4,0,25.1,0" />
          <path className="loading__element" fill="#4853bb" d="M34.8,17.8c0.6,0,1.2-0.2,1.6-0.7l6.5-6.5c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0l-6.5,6.5
    c-0.9,0.9-0.9,2.3,0,3.2C33.7,17.6,34.3,17.8,34.8,17.8"/>
          <path className="loading__element" fill="#4853bb" d="M48,22.9h-9.1c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3H48c1.3,0,2.3-1,2.3-2.3
    C50.3,23.9,49.3,22.9,48,22.9"/>
          <path className="loading__element" fill="#4853bb" d="M36.5,33.3c-0.9-0.9-2.3-0.9-3.2,0c-0.9,0.9-0.9,2.3,0,3.2l6.5,6.5c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7
    c0.9-0.9,0.9-2.3,0-3.2L36.5,33.3z"/>
          <path className="loading__element" fill="#4853bb" d="M25.1,36.6c-1.3,0-2.3,1-2.3,2.3v9.1c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3v-9.1
    C27.4,37.6,26.4,36.6,25.1,36.6"/>
          <path className="loading__element" fill="#4853bb" d="M13.8,33.3l-6.5,6.5c-0.9,0.9-0.9,2.3,0,3.2c0.4,0.4,1,0.7,1.6,0.7s1.2-0.2,1.6-0.7l6.5-6.5
    c0.9-0.9,0.9-2.3,0-3.2C16.2,32.4,14.7,32.4,13.8,33.3"/>
          <path className="loading__element" fill="#4853bb" d="M13.7,25.2c0-1.3-1-2.3-2.3-2.3H2.3c-1.3,0-2.3,1-2.3,2.3c0,1.3,1,2.3,2.3,2.3h9.1
    C12.7,27.5,13.7,26.5,13.7,25.2"/>
          <path className="loading__element" fill="#4853bb" d="M15.4,17.8c-0.6,0-1.2-0.2-1.6-0.7l-6.5-6.5c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0l6.5,6.5
    c0.9,0.9,0.9,2.3,0,3.2C16.6,17.6,16,17.8,15.4,17.8"/>
        </svg>
      </div>

    </div>
  )
}

export default Loader