import Axios from 'axios';
import toast from 'react-hot-toast';
import apiUrl from './ApiUrl';
import React from "react"
import Helpmate from '../Helper/Common';
import Encode from "../Helper/Encodes"


function getURL(type, serviceName) {

    let url;

    if (type === 'all') {

        url = apiUrl.APIURL() + '/' /* + process.env.REACT_APP_BEFORELOGINURL + '/' */ + serviceName

    } else if (type === 'user') {

        url = apiUrl.APIURL() + '/' + process.env.REACT_APP_AFTERLOGINURL + '/' + serviceName

    } else if (type === 'geturl') {

        url = apiUrl.APIURL() + '/' + process.env.REACT_APP_BEFORELOGINGETURL + '/' + serviceName

    } else if (type === 'exchange') {

        url = apiUrl.EXCHANGEURL() + '/' + process.env.REACT_APP_EXCHANGEROUTEURL + '/' + serviceName

    } else {

        url = '';
    }

    return url;
}


function tosterDisplay(type, text) {

    if (text.length > 0) {

        toast.dismiss();

        (type === 'success') ? toast.success(<div dangerouslySetInnerHTML={{ __html: text }} />) : toast.error(<div dangerouslySetInnerHTML={{ __html: text }} />);
    }
}


const connectBackend = {

    ApiHelper: async function (serviceName, payload = {}, method = 'POST', type = "all") {

        let requestParams = {}

        var FormmdataApi = ['KYCupload', 'userProfileImageUpdate']

        const baseURL = getURL(type, serviceName);

        if (FormmdataApi.includes(serviceName)) {
            requestParams = payload;
        }
        else {
            const ciphertext = payload
            requestParams = { payload: ciphertext }
        }

        const baseHeadersConfig = {
            "Content-Type": FormmdataApi.includes(serviceName) ? "multipart/form-data" : "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "true",
            "deviceType": "website",
        };

        if (localStorage.getItem("MetaMaskToken")) {
            baseHeadersConfig['x-access-token'] = localStorage.getItem("MetaMaskToken");
        }

        const headersConfig = baseHeadersConfig;

        return await Axios({
            url: baseURL,
            method: method,
            data: requestParams,
            headers: headersConfig,
        }).then(async (result) => {
            if (result && result?.data) {

                if (result.status === 200) {
                    let responseData = result?.data;

                    let response = await responseData?.payload;

                    if (response?.status) {
                        let payloadData = response?.payload;

                        (response?.message) && tosterDisplay('success', response?.message);

                        if (payloadData) {

                            return payloadData;

                        } else {

                            return response;
                        }

                    } else {

                        (response?.message) && tosterDisplay('error', response?.message);

                        if (+(response?.code) === 1 || +(response?.code) === 2 || +(response?.code) === 3) {
                            if (+(response?.code) === 1) {
                                Helpmate.LocalstorageRemove()
                                window.location.href = "/"
                            } else if (+(response?.code) === 2) {
                                Helpmate.LocalstorageRemove()
                                localStorage.setItem('MainTanence', 1)
                                localStorage.setItem('MainTanenceContent', Encode.payloadencryption(response?.content));
                                window.location.href = "/maintenance"

                            } else if (+(response?.code) === 3) {
                                Helpmate.LocalstorageRemove()
                                localStorage.setItem('IPBlock', 1)
                                localStorage.setItem('IPContent', Encode.payloadencryption(response?.content));
                                window.location.href = "/ipblock"

                            }

                        }
                        if (response?.expires || response?.Maintenance) {
                            return response
                        } else if (response?.code === 4 || response?.code === 5) {
                            return response
                        }
                        else {
                            return false;
                        }

                    }

                } else {

                    tosterDisplay('error', 'Connection timed out.');

                    return false;
                }

            } else {

                tosterDisplay('error', 'Invalid request data.');

                return false;
            }

        }, (error) => {
            console.log(error)
            if (error.code !== "ERR_CANCELED") {
                tosterDisplay('error', 'Server Busy !');
            }

            return false;
        }).catch((error) => {
            console.error(error);
        });
    }

}

export default connectBackend;