import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/main.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import './assets/css/icon.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Toaster } from 'react-hot-toast';
// import { MetaMaskProvider } from "@metamask/sdk-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Toaster containerClassName="toast_message" gutter={100}

      toastOptions={{

        success: {
          duration: 4000,
          zIndex: 1,
          iconTheme: { primary: '#84d32a', secondary: 'white' },
          style: { background: '#4d9100', color: 'white', position: "relative", zIndex: 1 },
        },

        error: {
          duration: 4000,
          zIndex: 1,
          iconTheme: { primary: 'red', secondary: 'white' },
          style: { background: '#c51818', color: 'rgb(241 241 241)', fontSize: '14px', position: "relative", zIndex: 1 },
        },
      }}
    />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
