import React, { useEffect, useState } from 'react'
import LoginPage from './LoginPage'
import { connect } from "react-redux";
import { BsSun } from "react-icons/bs";
import { onChangeDarkMode } from "../../../Redux/Actions/Actions";
import { Link } from 'react-router-dom';
const Header = (props) => {
  const { darkMode } = props.Mainreducer;
  const handleTheme = () => {
    if (darkMode === "dark") {
      props.onChangeDarkMode("light");
    } else {
      props.onChangeDarkMode("dark");
    }
  };

  const [metamask, SetMetamask] = useState("");

  useEffect(() => {
    let theme = localStorage.getItem("data-theme") ? localStorage.getItem("data-theme") : "dark"
    props.onChangeDarkMode(theme)
    let mask = localStorage.getItem("MetaMaskToken")

    if (mask) {
      SetMetamask(mask);

    } else {
      SetMetamask("");
    }

  }, [])

  return (
    <div>
      <div className='hesadersec '>
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <Link to="/" className="navbar-brand" >
              <img src={process.env.PUBLIC_URL + "/images/home/icologo.png"} className='img-fluid' />
            </Link>
            <button
              className="btn  navbar-toggler border-3 px-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasExample"
              aria-controls="offcanvasExample"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="bars" width="30" height="30" fill='#f18c20'><path d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"></path></svg>
            </button>
            <div
              className="offcanvas offcanvas-start-lg "
              tabIndex={-1}
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header d-flex d-lg-none">
                <h5 className="offcanvas-title text-black" id="offcanvasExampleLabel">
                  ICO
                </h5>
                <Link to="#"

                  className="text-reset p-0"
                  data-bs-dismiss="offcanvas"
                  aria-label="close"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="#000"
                    className="bi bi-x-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </Link>
              </div>
              <div className="offcanvas-body p-lg-0 align-items-center">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active" aria-current="page">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className="nav-link active" aria-current="page" >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/trade" className="nav-link active" aria-current="page">
                      Trade
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact" className="nav-link active" aria-current="page">
                      Contact
                    </Link>
                  </li>
                  {metamask !== ""? <li className="nav-item">
                    <Link to="/dashboard" className="nav-link active" aria-current="page">
                      Dashboard
                    </Link>
                  </li> : ''}

                </ul>

                <div className="offcanvastwo  ms-auto ">
                  <ul className='navbar-nav mb-2  mb-lg-0 d-flex align-items-center'>
                    <li>
                      <LoginPage />
                    </li>
                    <li>
                      <div className="setico">
                        {darkMode === "dark" ? (
                          <i onClick={() => handleTheme()} style={{ cursor: "pointer" }} className="moon">
                            <svg
                              stroke="currentColor"
                              fill="#fff"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z"></path>
                            </svg>
                          </i>
                        ) : (
                          <i onClick={() => handleTheme()} style={{ cursor: "pointer" }} className="sun">
                            <BsSun />
                          </i>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>

      </div>
    </div>
  )
}

const mapStateToProps = ({ Mainreducer }) => ({ Mainreducer });

export default connect(mapStateToProps, { onChangeDarkMode })(Header);