import CryptoJS from 'crypto-js';

const Encode = {

	payloadencryption: function (data) {
		let key = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCPAYKEY);
		let iv = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCPAYIV);
		let value = (data) ? CryptoJS?.AES?.encrypt((data).toString(), key, { iv: iv }).toString() : false;

		return value;
	},

	payloaddecryption: function (data) {
		let key = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCPAYKEY);
		let iv = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCPAYIV);
		let value = (data) ? CryptoJS?.AES?.decrypt(data, key, { iv: iv }).toString(CryptoJS.enc.Utf8) : false;

		return value;
	},

	Decrypt: function (data) {
		let key = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCKEY);
		let iv = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCIV);
		let value = (data) ? CryptoJS.AES.decrypt(data, key, { iv: iv }).toString(CryptoJS.enc.Utf8) : false;

		return value;

	},

	objectEncrypt: function (data) {
		let key = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCKEY);
		let iv = CryptoJS?.enc?.Base64?.parse(process.env.REACT_APP_ENCIV);
		let value = (data) ? CryptoJS?.AES?.encrypt((data).toString(), key, { iv: iv }).toString() : false;

		return value;

	}

}

export default Encode;