import Carousel from "react-bootstrap/Carousel";
import React, { useState } from "react";

const Hero = () => {

  return (
    <div className='bannersec  py-5' id="stars-container">
      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>


      <Carousel fade interval={null} indicators={false} prevIcon={null} nextIcon={null}>

        <Carousel.Item>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='bannercontent text-start'>
                  <span>Discover a new ways to enjoy your World!</span>
                  <h1 className='mb-3 mt-3'>Invest In Cryptocoin Way To Trade</h1>
                  <p className='mb-3'>We have over 15 year exprience in business consultting arena. We have over 15 year exprience in business consultting arena and artficial intelligence.</p>
                  <div className="d-flex gap-3 conectbtns mt-4">
                    <button type='button' className='btn'>TradeNow</button>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className="bannersec-one">
                  <div className="banner-ico">
                    <div className="image">
                      <img src={process.env.PUBLIC_URL + "/images/home/home-banner1.png"} className='img-fluid' />
                    </div>
                    {/* <div className="shape-image1 bounce-animate">
                      <img src={process.env.PUBLIC_URL + "/images/home/home-banner2.png"} className='img-fluid' />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <img src={process.env.PUBLIC_URL + "/images/home/home-banner2.png"} className='img-fluid' />
              </div>
              <div className='col-lg-6'>
                <div className='bannercontent text-start'>
                  <span>Discover a new ways to enjoy your World!</span>
                  <h1 className='mb-3 mt-3'>Get Most Benefits of ICO, crypto & Forex Trading</h1>
                  <p className='mb-3'>We have over 15 year exprience in business consultting arena. We have over 15 year exprience in business consultting arena and artficial intelligence.</p>
                  <button type='button' className='btn'>TradeNow</button>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>

  )
}

export default Hero