import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (

        <div>



            <footer
                className="footersec text-center text-lg-start text-black"

            >

                <section className="">
                    <div className="container text-center text-md-start mt-5">

                        <div className="row mt-3">

                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                {/* Content */}
                                <h6 className="text-uppercase fw-bold">Company name</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: 60, backgroundColor: "var(--border-color)", height: 2 }}
                                />
                                <p>
                                    Here you can use rows and columns to organize your footer
                                    content. Lorem ipsum dolor sit amet, consectetur adipisicing
                                    elit.
                                </p>
                                <div>
                                    <Link to="#" className="text-black me-4">
                                        <i className="fa fa-facebook-f" />
                                    </Link>
                                    <Link to="#" className="text-black me-4">
                                        <i className="fa fa-twitter" />
                                    </Link>
                                    <Link to="#" className="text-black me-4">
                                        <i className="fa fa-google" />
                                    </Link>
                                    <Link to="#" className="text-black me-4">
                                        <i className="fa fa-instagram" />
                                    </Link>
                                    <Link to="#" className="text-black me-4">
                                        <i className="fa fa-linkedin" />
                                    </Link>
                                    <Link to="#" className="text-black me-4">
                                        <i className="fa fa-github" />
                                    </Link>
                                </div>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold">Products</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                                />
                                <p>
                                    <a href="#!" className="text-black">
                                        About
                                    </a>
                                </p>
                                <p>
                                    <a href="#!" className="text-black">
                                        Contact
                                    </a>
                                </p>
                                <p>
                                    <a href="#!" className="text-black">
                                        Services
                                    </a>
                                </p>
                                <p>
                                    <a href="#!" className="text-black">
                                        Tokens
                                    </a>
                                </p>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold">Useful links</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                                />
                                <p>
                                    <a href="#!" className="text-black">
                                        Your Account
                                    </a>
                                </p>
                                <p>
                                    <a href="#!" className="text-black">
                                        Become an Affiliate
                                    </a>
                                </p>
                                <p>
                                    <a href="#!" className="text-black">
                                        Marketing
                                    </a>
                                </p>
                                <p>
                                    <a href="#!" className="text-black">
                                        Faq
                                    </a>
                                </p>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold">Contact</h6>
                                <hr
                                    className="mb-4 mt-0 d-inline-block mx-auto"
                                    style={{ width: 60, backgroundColor: "#7c4dff", height: 2 }}
                                />
                                <p>
                                    <i className="fa fa-home mr-3" /> New York, NY 10012, US
                                </p>
                                <p>
                                    <i className="fa fa-envelope mr-3" /> info@example.com
                                </p>
                                <p>
                                    <i className="fa fa-phone mr-3" /> + 01 234 567 88
                                </p>
                                <p>
                                    <i className="fa fa-print mr-3" /> + 01 234 567 89
                                </p>
                            </div>
                            {/* Grid column */}
                        </div>
                        {/* Grid row */}
                    </div>
                </section>
                {/* Section: Links  */}
                {/* Copyright */}
                {/* <div
                    className="footercont text-center p-3"
                    
                >
                   <span>© 2024 Copyright:</span> 
                    <a className="icofooter mx-1" href="#">
                       Ico.com
                    </a>
                </div> */}
                {/* Copyright */}
            </footer>

        </div >




    )
}

export default Footer